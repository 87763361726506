// GalleryPage.js
import React from 'react';
import ImageSlider from './ImageSlider'; // Import the ImageSlider component

const SliderWeek14_2 = ({ onImageIndexChange }) => {
  const slideImages = [
    'https://res.cloudinary.com/ahbranding/image/upload/v1722267781/AHbranding/Slide_16_9_-_91_iisojo.png',
    'https://res.cloudinary.com/ahbranding/image/upload/v1722267820/AHbranding/Slide_16_9_-_90_mwnikg.png',
  ];

  const handleImageIndexChange = (index) => {
    if (onImageIndexChange) {
      onImageIndexChange(index);
    }
  };


  return (
    <div className="gallery-page">
      <ImageSlider className="w-full" onImageIndexChange={handleImageIndexChange} images={slideImages} /> {/* Integrate the ImageSlider component */}
    </div>
  );
};

export default SliderWeek14_2;
