import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from 'react-hot-toast';
import SliderWeek6 from "./SliderWeek6";
import SliderWeek6_2 from "./SliderWeek6_2";
import SliderWeek6_3 from "./SliderWeek6_3";
import SliderWeek6_4 from "./SliderWeek6_4";
import Quote from "../components/Quote";
import Collapse from "../components/Collapse";
import Vimeo from '@u-wave/react-vimeo';
import SliderWeek8 from "./SliderWeek8";
import CardFlip from "./CardFlip";
import SliderWeek9 from "./SliderWeek9";
import SliderWeek10 from "./SliderWeek10";
import SliderWeek10_2 from "./SliderWeek10_2";
import QuizComponent from "./QuizComponent";
import SliderWeek11 from "./SliderWeek11";
import SliderWeek11_2 from "./SliderWeek11_2";
import SliderWeek13 from "./SliderWeek13";
import { axiosInstance } from "../Axios";
import SliderWeek2 from "./SliderWeek2";
import SliderWeek14_2 from "./SliderWeek14_2";
import Confetti from 'react-confetti';
const Week13 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 16;
  const week_name = "week_14";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);


  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [swotSubmission, setSubmission] = useState({
    response: "",
  });
  const [assignedReview, setAssigned] = useState({
    stakeholders: "",
    choices: "",
    principles: "",
  });
  const [swotFeedback, setFeedback] = useState("");

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway_id,
      })
      .then((response) => {
        console.log(response);
        toast.success('', {
          icon: '',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            // strengths: data.strengths,
            // weaknesses: data.weaknesses,
            // opportunities: data.opportunities,
            // threats: data.threats,
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
          });
          setFeedback(response.data.feedback);
        }
      });
  };
  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`, {
        user_id: user_id,
        week_id: week_id,
        week_name: week_name,
        section: section,
        pathway: pathway,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          toast.success('', {
            icon: '',
            style: {
              borderRadius: '55px',
              marginBottom: '15px',
            },
          });
          setSubmission({
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
            
          });
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 4, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 4);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "eqCNw8WXtQ8"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axiosInstance
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_15",
              }
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log(swotSubmission);
  const handleStepSubmission = () => {
    console.log("this steo", currentStep);
    if (status === "Active" || status == "Completed") {
      if (currentStep === 5) {
        axiosInstance.post(`${process.env.REACT_APP_API_KEY}/api/peer_feedback/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: 4,
          content: swotSubmission,
          pathway: 1,
          feedback: swotFeedback,
          rating: 0
        });
        toast.success('Good Job! Feedback Submitted', {
          icon: '',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
        return;
      } else {
        axiosInstance.post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: currentStep,
          content: swotSubmission,
          pathway: 1,
          status: "Reviewing",
          rating: 0,
          pr_enabled: true,
          activity_name: "Risk Analysis Scenario",
        });
        toast.success('', {
          icon: '',
          style: {
            borderRadius: '55px',
            marginBottom: '15px',
          },
        });
      }
    }
  };

  const totalSteps = 7; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };


  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    setShowExplanation(true);
  };

  const handleNextQuestionOrFinish = () => {
    if (currentQuestion + 1 < questionsData.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption("");
      setShowExplanation(false);
    }
  };





  const [currentQuestion2, setCurrentQuestion2] = useState(0);
  const [selectedOption2, setSelectedOption2] = useState("");
  const [showExplanation2, setShowExplanation2] = useState(false);

  const handleOptionSelect2 = (option2) => {
    setSelectedOption2(option2);
  };

  const handleNextQuestion2 = () => {
    setShowExplanation2(true);
  };

  const handleNextQuestionOrFinish2 = () => {
    if (currentQuestion2 + 1 < questionsData2.length) {
      setCurrentQuestion2(currentQuestion2 + 1);
      setSelectedOption2("");
      setShowExplanation2(false);
    }
  };


  const questionsData = [
    {
      question: "What is the first step in the reporting mechanism within LUMS societies for incidents of harassment?",
      options: [
        "A) Contacting the University Security",
        "B) Reporting directly to the Disciplinary Committee (DC)",
        "C) Documenting the incident with the society's designated primary contact",
        "D) Sending an email to the Office of Accessibility and Inclusion (OAI)"
      ],
      correctAnswer: "C) Documenting the incident with the society's designated primary contact",
      explanation: "The first step in addressing an incident within societies is to report it to the designated primary contact, who is trained and informed on how to handle initial reports effectively."
    },
    {
      question: "Which office is primarily responsible for consulting on incidents that fall under sexual harassment at LUMS?",
      options: [
        "A) Co-Curricular Activities Office",
        "B) Office of Accessibility and Inclusion (OAI)",
        "C) Disciplinary Committee (DC)",
        "D) Executive Council of the society"
      ],
      correctAnswer: "B) Office of Accessibility and Inclusion (OAI)",
      explanation: "The Office of Accessibility and Inclusion (OAI) is primarily responsible for consulting and guiding the next steps when an incident of sexual harassment is reported."
    },
    {
      question: "What role does the Executive Council play in the context of the LUMS Sexual Harassment Policy?",
      options: [
        "A) Directly handling and sanctioning reports of harassment",
        "B) Providing initial guidance and ensuring compliance with university policies",
        "C) Investigating reports of harassment independently",
        "D) Issuing final decisions on reported cases"
      ],
      correctAnswer: "B) Providing initial guidance and ensuring compliance with university policies",
      explanation: "The Executive Council is responsible for ensuring that all members are familiar with the policies, facilitating regular training sessions, and ensuring compliance, but they do not handle the reports directly or issue final decisions on cases."
    },
    {
      question: "According to the policy, what type of training is mandatory for society members?",
      options: [
        "A) Training on financial management",
        "B) Sexual harassment policy training",
        "C) Event management training",
        "D) Leadership skills training"
      ],
      correctAnswer: "B) Sexual harassment policy training",
      explanation: "Mandatory training on the university’s sexual harassment policy is required for all society members to ensure they understand how to handle situations appropriately and are aware of the policy details."
    }
  ];
  

  
  const questionsData2 = [
    {
      question: "Effective coping strategies in leadership are only about managing personal stress.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Effective coping strategies in leadership encompass managing personal stress and fostering resilience within the team.",
    },
    {
      question: "Emotional resilience in leadership does not impact decision-making and team dynamics.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Emotional resilience is crucial for effective leadership as it positively impacts decision-making and team dynamics.",
    },
    {
      question: "Regular stress-management practices are unrelated to overall leadership effectiveness.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Regular stress-management practices are essential for maintaining clarity, focus, and overall leadership effectiveness.",
    },
    {
      question: "Creating a supportive environment for resilience is solely the responsibility of individual team members.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Leaders play a critical role in cultivating a team culture that supports resilience and stress management.",
    },
    {
      question: "Learning from successful leaders who manage stress effectively is not beneficial for developing resilience.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation: "Observing and learning from successful leaders who effectively manage stress can provide valuable insights for developing resilience.",
    },
  ];
  
  
  const accordionData = [
    {
      title: 'Uncomfortable Invitations',
      content: (
        <div>
          <p>Inviting members for unofficial meetings outside campus with the allure of leadership positions or other society privileges.</p>
        </div>
      ),
    },
    {
      title: 'Unsolicited Favors',
      content: (
        <div>
          <p>Senior society members demanding favours from junior members in exchange for roles in society events or activities.</p>
        </div>
      ),
    },
    {
      title: 'Rumors and Defamation',
      content: (
        <div>
          <p>Spreading rumours or false information about society members to tarnish their reputation within the society or the larger student community.</p>
        </div>
      ),
    },
    {
      title: 'Bullying and Intimidation',
      content: (
        <div>
          <p>Bullying or intimidation by senior society members towards newcomers or minority students.</p>
        </div>
      ),
    },
    {
      title: 'Inappropriate Comments',
      content: (
        <div>
          <p>Making unwarranted and inappropriate comments about a member's appearance or personal life during society meetings or events.</p>
        </div>
      ),
    },
    {
      title: 'Private Gatherings',
      content: (
        <div>
          <p>Inviting members to private gatherings under the guise of society work and making inappropriate advances or comments.</p>
        </div>
      ),
    },
    {
      title: 'Offensive Content',
      content: (
        <div>
          <p>Sharing or promoting offensive content, such as inappropriate jokes or memes, within society group chats or social media platforms.</p>
        </div>
      ),
    },
    {
      title: 'Gestures and Remarks',
      content: (
        <div>
          <p>Staring, making inappropriate gestures, or passing suggestive remarks during society events or rehearsals.</p>
        </div>
      ),
    },
    {
      title: 'Personal Discussions',
      content: (
        <div>
          <p>Discussing intimate experiences or relationships within the society, especially if it's done to embarrass or mock someone.</p>
        </div>
      ),
    },
    {
      title: 'Derogatory Language',
      content: (
        <div>
          <p>Using derogatory language that objectifies or demeans members based on their gender or appearance.</p>
        </div>
      ),
    },
    {
      title: 'Physical Contact',
      content: (
        <div>
          <p>Making unwarranted physical contact, like deliberately bumping into someone during society events or activities.</p>
        </div>
      ),
    },
    {
      title: 'Mocking Attributes',
      content: (
        <div>
          <p>Commenting on or mocking personal attributes of members, such as body shape, voice, or attire, especially with a sexual undertone.</p>
        </div>
      ),
    },
    {
      title: 'Explicit Content',
      content: (
        <div>
          <p>Sharing or discussing explicit content related to a society member on society platforms or group chats.</p>
        </div>
      ),
    },
    {
      title: 'Financial Manipulation',
      content: (
        <div>
          <p>Offering financial aid or resources to society members in return for personal favours.</p>
        </div>
      ),
    },
    {
      title: 'Inappropriate Jokes',
      content: (
        <div>
          <p>Making inappropriate comments or jokes during society presentations, performances, or meetings.</p>
        </div>
      ),
    },
    {
      title: 'Prolonged Meetings',
      content: (
        <div>
          <p>Holding prolonged private meetings with a society member without a clear agenda or reason.</p>
        </div>
      ),
    },
    {
      title: 'Shaming',
      content: (
        <div>
          <p>Publicly or privately shaming a member based on rumours or personal biases.</p>
        </div>
      ),
    },
    {
      title: 'Unsolicited Messages',
      content: (
        <div>
          <p>Sending unsolicited messages or notes with inappropriate content or requests.</p>
        </div>
      ),
    },
    {
      title: 'Manipulation',
      content: (
        <div>
          <p>Using one's position in the society to manipulate or coerce members into unwanted situations.</p>
        </div>
      ),
    },
    {
      title: 'Fake Content',
      content: (
        <div>
          <p>Creating or sharing fake content to defame or blackmail society members.</p>
        </div>
      ),
    },
    {
      title: 'Invasion of Space',
      content: (
        <div>
          <p>Touching or making gestures that invade a member's personal space without consent.</p>
        </div>
      ),
    },
    {
      title: 'Objectifying Content',
      content: (
        <div>
          <p>Using society platforms to promote or share content that objectifies or demeans any gender.</p>
        </div>
      ),
    },
    {
      title: 'Forced Roles',
      content: (
        <div>
          <p>Forcing members into roles or tasks against their will as a form of punishment or control.</p>
        </div>
      ),
    },
    {
      title: 'Threats',
      content: (
        <div>
          <p>Threatening members with false evidence or information to gain compliance or silence.</p>
        </div>
      ),
    },
    {
      title: 'Anonymous Messages',
      content: (
        <div>
          <p>Sending anonymous messages that aim to defame or intimidate society members.</p>
        </div>
      ),
    },
  ];
  

  const accordionData2 = [
    {
      title: 'Q1: What is sexual harassment?',
      content: (
        <div>
          <p>Sexual harassment is any unwelcome sexual advance, request for sexual favors, or other verbal or written communication or physical conduct of a sexual nature, or sexually demeaning attitude, causing interference with work performance or creating an intimidating, hostile or offensive work environment, or the attempt to punish the complainant for refusal to comply to such a request or is made a condition for employment. Sexual harassment may involve the behaviour of a person of either sex against a person of the opposite or same sex.</p>
          
        </div>
      ),
    },
    {
      title: 'Q2: What should I do if I experience or witness sexual harassment?',
      content: (
        <div>
          <p>At the time the incident is witnessed or experienced, write down what happened, including dates, times, and any witnesses. Report it to a trusted member of your student society, a CCA Officer, or directly to the Office of Accessibility and Inclusion (OAI). It's important to speak up so that the behavior can be addressed and stopped.</p>
        </div>
      ),
    },
    {
      title: 'Q3: Will my report be kept confidential?',
      content: (
        <div>
          <p>Yes, your identity and the details of your report will be kept confidential to protect your privacy and ensure your safety.</p>
        </div>
      ),
    },
    {
      title: "Q4: What if I'm not sure if what happened to me is sexual harassment?",
      content: (
        <div>
          <p>If you feel uncomfortable or unsure, talk to someone you trust, such as a designated EC member, your society’s designated CCA Officer or the OAI. They can help you understand and decide the best course of action.</p>
        </div>
      ),
    },
    {
      title: 'Q5: Can I have someone with me when I report sexual harassment?',
      content: (
        <div>
          <p>Yes, both the complainant and the accused reserve the right to be represented or accompanied by a representative, friend, or colleague during any inquiry process.</p>
        </div>
      ),
    },
  ];
  
  
  const accordionData3 = [
    {
      title: 'Recognize and Document the Incident: Identifying and Recording Harassment',
      content: (
        <div>
          <p>If you experience or witness harassment, recognize it and document the details. This can include the date, time, location, nature of the harassment, and names of any witnesses.</p>
        </div>
      ),
    },
    {
      title: 'Contact the Concerned Department: Reaching Out for Help',
      content: (
        <div>
          <p>LUMS has designated departments and individuals to handle harassment cases:</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Email the Office of Student Affairs: <a href="mailto:studentaffairs@lums.edu.pk">studentaffairs@lums.edu.pk</a> for guidance on next steps.</li>
            <li>Contact the Harassment Committee: Specific email addresses and contact details of committee members can be found on the LUMS website or through the student portal.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Formal Reporting Procedures: Official Reporting Mechanism',
      content: (
        <div>
          <p>To formally report harassment:</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Fill out the harassment complaint form available online on the LUMS portal.</li>
            <li>Submit the form to the Harassment Committee via their official email <a href="mailto:harassment@lums.edu.pk">harassment@lums.edu.pk</a>.</li>
            <li>You can also request a meeting with a committee member or member of the student council for a more personal approach.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Seeking Immediate Support: Accessing Immediate Assistance',
      content: (
        <div>
          <p>If you need immediate assistance or counselling:</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Reach out to the LUMS Counselling and Psychological Services at <a href="mailto:counseling@lums.edu.pk">counseling@lums.edu.pk</a>.</li>
            <li>Contact your department head or a trusted faculty member for immediate advice and support.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Bystander Intervention and Informal Support: The Role of Bystanders',
      content: (
        <div>
          <p>As a bystander, you can support victims by:</p>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>Offering immediate assistance or escorting them to a safe place.</li>
            <li>Encouraging them to document the incident and seek help.</li>
            <li>Accompanying them to report the incident if they feel comfortable.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Follow-up and Continual Support: Post-Reporting Follow-up',
      content: (
        <div>
          <p>After reporting, stay in touch with the concerned department for updates. Continue to access support services as needed. LUMS ensures confidentiality and continued support for all individuals involved in harassment cases.</p>
        </div>
      ),
    },
  ];
  
  



  const accordionData9 = [
    {
      title: 'Step 1 - Initial Report',
      content: (
        <div>
          <p>Once an incident is reported to the designated primary contact within the society, they should document the details, ensuring accuracy and confidentiality.</p>
        </div>
      ),
    },
    {
      title: 'Step 2 - Preliminary Review',
      content: (
        <div>
          <p>The primary contact should conduct a preliminary review of the report to determine its severity and the immediate actions required.</p>
        </div>
      ),
    },
    {
      title: 'Step 3 - Determination of Case Type',
      content: (
        <div>
          <p>Based on the nature of the incident, it should be determined whether the case falls under sexual harassment or another type of conduct violation. If society is uncertain or requires consultation regarding the classification of the incident, they are encouraged to reach out to the Office of Accessibility and Inclusion (OAI) for guidance and clarity.</p>
          <ul>
            <li><strong>Consultation with OAI:</strong> For incidents that fall under the purview of sexual harassment, the Office of Accessibility and Inclusion (OAI) should be consulted immediately.</li>
            <li><strong>Forwarding to the DC (for Other Conduct Violations):</strong> If the incident pertains to other conduct violations, it should be forwarded to the Disciplinary Committee (DC).</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Step 4 - Formal Investigation',
      content: (
        <div>
          <p>Depending on the nature of the case, either the OAI (for sexual harassment) or the DC (for other conduct violations) will initiate a formal investigation. Society’s role is to cooperate fully and provide any necessary information. Both the complainant and the accused reserve the right to be represented or accompanied by a representative, friend, or colleague during any inquiry process. Societies should ensure that their members are aware of this provision.</p>
        </div>
      ),
    },
    {
      title: 'Step 5 - Decision',
      content: (
        <div>
          <p>Based on the findings of the investigation, either the OAI or the DC will decide on the appropriate actions.</p>
        </div>
      ),
    },
    {
      title: 'Step 6 - Feedback to Parties',
      content: (
        <div>
          <p>All stakeholders involved in the incident should be informed about the outcome of the investigation and the actions taken, ensuring transparency while maintaining the confidentiality of all parties involved.</p>
        </div>
      ),
    },
    {
      title: 'Step 7 - Action by Competent Authority',
      content: (
        <div>
          <p>The competent authority shall impose the penalty recommended by the relevant authority within one (1) week of the receipt of the recommendation. Society must adhere to any directives or recommendations provided.</p>
        </div>
      ),
    },
    {
      title: 'Step 8 - Review and Reflection',
      content: (
        <div>
          <p>Periodically, the Executive Council should review the escalation process in collaboration with CCA, OAI, and the DC to identify areas for improvement and ensure that it remains effective and aligned with university guidelines.</p>
        </div>
      ),
    },
  ];
  



  

  const accordionData82 = [
    {
      title: 'Cultivate a Shared Vision',
      content: '- Establishing a shared vision is fundamental for aligning team efforts towards common goals.\n' +
        'Reflect on your recent team interactions:\n' +
        '- How have I communicated and fostered a shared vision within the team?\n' +
        '- In what ways have I ensured that everyone understands and is committed to our collective goals?\n' +
        '- What strategies can I employ to reinforce our vision and keep the team motivated and focused on long-term objectives?',
    },
    {
      title: 'Analyse and Set SMART Goals',
      content: '- Effective goal setting is a collaborative and strategic process. Evaluate your team\'s approach to setting goals:\n' +
        '- How do we define and agree upon our goals? Are they Specific, Measurable, Achievable, Relevant, and Time-bound (SMART)?\n' +
        '- Where can we improve in making our goals more aligned with our strategic vision?\n' +
        '- How can I lead the team to break down our larger vision into achievable, strategically informed goals?',
    },
    {
      title: 'Encourage Participation in Vision and Goal Setting',
      content: '- Active participation in vision and goal setting is key to team buy-in and commitment. Assess your role in facilitating this involvement:\n' +
        '- Have I created opportunities for every team member to contribute to our vision and goal-setting process?\n' +
        '- How can I better ensure that all voices are heard and considered in shaping our team\'s direction?\n' +
        '- In what ways can I empower each member to take ownership of parts of our vision and goals, thereby strengthening their commitment and the team\'s cohesion?',
    },
  ];
  

  const accordionData4 = [
    {
      title: 'Developing a Structured Research Approach',
      content: '- Structuring your research is vital for effective information gathering.\n' +
        '- Reflect on your recent research tasks:\n' +
        '  - How do I define my research objectives?\n' +
        '  - What strategies am I using to collect and organize information?\n' +
        '  - How do I ensure the sources I use are credible and relevant?',
    },
    {
      title: 'Analysing Information and Drawing Insights',
      content: '- The essence of research lies in analyzing information and extracting valuable insights.\n' +
        '- Assess your recent research endeavors:\n' +
        '  - How do I analyze the data and information I gather?\n' +
        '  - In what ways do I synthesize information to draw meaningful conclusions?\n' +
        '  - How do I ensure my analysis is unbiased and objective?',
    },
    {
      title: 'Incorporating Diverse Perspectives and Feedback',
      content: '- Including diverse perspectives enhances the depth and breadth of your research.\n' +
        '- Evaluate your approach to incorporating different viewpoints:\n' +
        '  - Have I considered alternative perspectives in my research?\n' +
        '  - How do I seek and incorporate feedback to refine my research?\n' +
        '  - What steps do I take to challenge my assumptions and broaden my understanding?',
    },
  ];
  
  const accordionData5 = [
    {
      title: 'Fundamentals of Financial Statements and Budgets',
      content: '- Mastering the basics of financial statements and budgets is fundamental.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I interpret key financial documents like balance sheets and income statements?\n' +
        '  - In what ways do I apply this understanding to manage budgets for society events or personal finance projects?\n' +
        '  - What resources or tools could enhance my skills in financial statement analysis?',
    },
    {
      title: 'Evaluating Financial Health and Performance',
      content: '- Assessing financial health is crucial for the sustainability of any initiative.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I determine the financial viability of a project or society event?\n' +
        '  - What metrics or indicators are useful in assessing financial performance?\n' +
        '  - How can financial data guide more sustainable decision-making processes?',
    },
    {
      title: 'Risk Assessment in Financial Decision-Making',
      content: '- Understanding and managing financial risks is key to sound financial decision-making.\n' +
        '- Self-Reflection Points:\n' +
        '  - How do I identify potential financial risks in my projects or society activities?\n' +
        '  - What strategies do I employ to mitigate these risks?\n' +
        '  - How do I balance potential risks and rewards when making financial decisions?',
    },
  ];
  
  const accordionData6 = [
    {
      title: 'Explore the Pillars of Sustainable Leadership',
      content:
      'A sustainable leader foresees beyond immediate goals. They set a clear, inspiring long-term vision that guides actions and policies, anticipating future challenges and opportunities. By doing so, they ensure that the organization can adapt and thrive in an ever-changing world.\n\n' +
      'Ethical leadership is the cornerstone of trust and integrity. Sustainable leaders uphold high ethical standards, make transparent decisions, and act consistently with their values. They inspire others by leading by example and holding themselves accountable, thus fostering a culture of trust.\n\n' +
      'Leaders with a sustainable mindset prioritize the environment, recognizing that resources are finite. They innovate to reduce waste, conserve energy, and lower carbon footprints, setting new industry standards. Environmental stewardship becomes a part of the organization\'s identity and mission.\n\n',
    }
    
  ];


  const accordionData7 = [
    {
      title: 'Understanding Harassment',
      content: (
        <div>
          <p>
            You’ve explored the different types of harassment, learning to recognize and differentiate them from everyday conflicts. This knowledge is vital in identifying harassment early and taking appropriate action.
          </p>
        </div>
      ),
    },
    {
      title: 'Recognizing Harassment',
      content: (
        <div>
          <p>
            We delved into the subtle nuances of identifying harassment, emphasizing the importance of not normalizing problematic behaviors under the guise of less severe conflicts.
          </p>
        </div>
      ),
    },
    {
      title: 'Impact of Harassment',
      content: (
        <div>
          <p>
            You’ve gained insights into the wide-ranging emotional, psychological, and professional impacts of harassment on individuals, teams, and organizations, understanding that these effects vary greatly depending on the circumstances and individuals involved.
          </p>
        </div>
      ),
    },
    {
      title: 'Preventing Harassment',
      content: (
        <div>
          <p>
            We discussed individual strategies that you, as members of student societies, can implement to prevent harassment, emphasizing the role of personal action and responsibility in fostering a safe and respectful environment.
          </p>
        </div>
      ),
    },
    {
      title: 'Responding to Harassment',
      content: (
        <div>
          <p>
            The week concluded with guidance on how to respond to harassment, providing a step-by-step approach, including formal procedures and informal strategies to address and report such incidents at LUMS.
          </p>
        </div>
      ),
    },
  ];
  
  
  const cardData = [
    {
      id: 1,
      frontContent: 'Understanding and Upholding Policies: Personal Responsibility in Policy Adherence',
      backContent: (
        <div>
          <p>Content: Familiarize yourself with your society's anti-harassment policies. Understand what constitutes harassment and commit to upholding these standards in your interactions. Personal adherence to policies sets a standard for behaviour and helps create a safe environment for everyone.</p>
        </div>
      ),
    },
    {
      id: 2,
      frontContent: 'Promoting Inclusivity and Respect: Being an Advocate for Diversity and Respect',
      backContent: (
        <div>
          <p>Content: Actively promote an inclusive atmosphere by respecting diverse viewpoints and backgrounds. Challenge discriminatory jokes or comments when you encounter them. Your actions can influence the overall culture of the society, encouraging others to follow suit.</p>
        </div>
      ),
    },
    {
      id: 3,
      frontContent: 'Effective Communication: Open and Respectful Communication',
      backContent: (
        <div>
          <p>Content: Practice open, honest, and respectful communication. Address conflicts constructively and seek to understand others' perspectives. Clear communication can prevent misunderstandings that may lead to perceived harassment.</p>
        </div>
      ),
    },
    {
      id: 4,
      frontContent: 'Bystander Intervention: Taking Action as a Bystander',
      backContent: (
        <div>
          <p>Content: If you witness harassment, consider safe ways to intervene or support the victim. This could be through direct intervention, distraction, or seeking help from society leaders or authorities. Bystander intervention can stop harassment and show that such behaviour is unacceptable.</p>
        </div>
      ),
    },
    {
      id: 5,
      frontContent: 'Personal Reflection and Growth: Reflecting on Personal Biases and Behaviours',
      backContent: (
        <div>
          <p>Content: Regularly reflect on your own biases and behaviours. Consider how your actions and words might be perceived by others. Being self-aware and willing to grow personally is key to preventing harassment.</p>
        </div>
      ),
    },
    {
      id: 6,
      frontContent: 'Seeking and Offering Support: Building a Supportive Network',
      backContent: (
        <div>
          <p>Content: Be approachable and supportive to peers who might be experiencing harassment. Offer help, whether it’s listening, providing advice, or assisting in reporting the incident. Having a supportive network can make it easier for victims to come forward.</p>
        </div>
      ),
    },
    {
      id: 7,
      frontContent: 'Leading by Example: Role Modelling Positive Behaviour',
      backContent: (
        <div>
          <p>Content: Lead by example in all your interactions within the society. Demonstrate respect, kindness, and inclusivity. Your behaviour can set a positive example for others and contribute to a respectful society culture.</p>
        </div>
      ),
    },
    // Add more card data as needed
  ];
  

  const cardData2 = [
    {
      id: 1,
      frontContent: 'Setting Specific Adaptability Goals',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: Defining clear, achievable goals is the first step in your action plan.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>What specific aspects of adaptability do I want to develop or enhance?</li>
            <li>How can these adaptability goals contribute to my effectiveness as a student leader or society member?</li>
            <li>What are the measurable outcomes I aim to achieve through these goals?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 2,
      frontContent: 'Identifying Strategies and Resources',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: Determining the right strategies and resources is key to achieving your goals.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>What strategies will I employ to improve my adaptability in various situations?</li>
            <li>Which resources (like workshops, books, mentors) can assist in developing these skills?</li>
            <li>How will I integrate these strategies and resources into my regular activities or society responsibilities?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 3,
      frontContent: 'Creating a Timeline and Milestones',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: A structured timeline with milestones helps track progress and maintain focus.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>What is a realistic timeline for achieving my adaptability goals?</li>
            <li>What milestones can I set to monitor my progress over time?</li>
            <li>How will I adjust my plan if I encounter obstacles or new opportunities for growth?</li>
          </ul>
        </div>
      ),
    },
    {
      id: 4,
      frontContent: 'Reviewing and Adjusting the Plan',
      backContent: (
        <div>
          <strong></strong>
          <p>Content: Regular review and adjustment of your action plan ensure its effectiveness and relevance.</p>
          <strong>Self-Reflection Points:</strong>
          <ul className="px-8" style={{ listStyle: 'disc' }}>
            <li>How will I periodically review the progress of my action plan?</li>
            <li>What criteria will I use to evaluate its success or areas needing adjustment?</li>
            <li>How open am I to modifying my plan based on feedback and new experiences?</li>
          </ul>
        </div>
      ),
    },
    // Add more card data as needed
  ];
  
  

  const steps = [
    // Define the content and structure of each step
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
      <b>Week 14: Understanding and Addressing Harassment</b>
      </h2>
      <p className="mt-4 text-gray-600">
      Welcome to Week 14 of your transformative journey in the Ascent Leadership Program. This module addresses the sensitive yet crucial topic of harassment, providing you with the knowledge and tools to recognize, prevent, and address it effectively. Your primary goal as a leader is to ensure every society member is well-informed and prepared to foster an inclusive, and respectful community.      
      <br/> <br/>Understanding and adhering to LUMS harassment policy is crucial in maintaining a secure atmosphere. We aim to inform and guide students about their rights, responsibilities, and the proper procedures for reporting and addressing incidents of harassment. By fostering awareness and providing clear, actionable steps, we aim to empower every student to contribute to a culture of safety, empathy, and support.
      </p>
      <div className="w-full flex justify-end items-center pt-6">
             <span className="text-lg">- Team Ascent</span>
             </div>
      <div className="mt-6 flex justify-center w-full h-full">
      <Quote content={"The way to right wrongs is to turn the light of truth upon them."} person={"Ida B. Wells"}/>

      {/* <div
          style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
        >
          <div className="video-container">
            <Vimeo
      video={'898992480'}
      autoplay
    />

          </div>
        </div> */}
      </div>
    </section>,








<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-16">
      
      
          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>

              Introduction 

              </b>
              <br/>
              Sexual harassment
            </h2>

            {/* <div class="mt-8 bg-gray-100 border border-gray-200 text-sm text-gray-800 rounded-lg p-4 darki:bg-white/10 darki:border-white/20 darki:text-white" role="alert">
  <span class="font-bold">Disclaimer</span> 
  Please note that the following content covers only some common types of harassment and their typical impacts. Harassment can take various other forms, and the impact on individuals may vary greatly depending on personal circumstances and the context in which the harassment occurs. 
</div> */}



            <p className="mt-4 text-gray-600">
      
            Sexual harassment, which is any unwelcome sexual advance, request for sexual favors or other verbal or written communication or physical conduct of a sexual nature, or sexually demeaning attitudes, causing interference with work performance or creating an intimidating, hostile or offensive work environment, or the attempt to punish the complainant for refusal to comply to such a request or is made a condition for employment. Sexual harassment may involve the behaviour of a person of either sex against a person of the opposite or same sex.             </p>
      
            <div class="mt-8 bg-blue-100 border border-blue-200 text-sm text-blue-800 rounded-lg p-4 darki:bg-blue-800/10 darki:border-blue-900 darki:text-blue-500" role="alert">
  <span class="font-bold">Note</span> <br/>
If at any point, you feel uncomfortable around someone but can’t pinpoint or explain why, it is worth talking about. Rest assured; your anonymity will be our priority. Proceed to resources.
</div>
            <div className="mt-6">
              <div className="container flex flex-col justify-center mx-auto px-0 ">
                <div className="space-y-4">
                    <Collapse accordionData={accordionData}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      
      </div>
      </section>,









<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-16">
      
      
          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>

              Review
              </b>
            </h2>

 

            <p className="mt-4 text-gray-600">

            By understanding what sexual harassment is, recognizing the signs, knowing how to report it, and regularly reviewing these guidelines, we can ensure a safe and respectful environment for everyone.
            </p>
      
            <div className="mt-6">
              <div className="container flex flex-col justify-center mx-auto px-0 ">
                <div className="space-y-4">
                    <Collapse accordionData={accordionData2}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      
      </div>
      </section>,



<section
className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
style={{ height: "100%" }}
>

<h2 className="text-xl font-medium sm:text-xl">
          <b>Escalation Process</b>
        </h2>
        <p className="mt-4 text-gray-600">

        The Executive Council, in consultation with society members, should identify trusted and approachable individuals to act as the primary contacts for reporting incidents. Regular training sessions, in collaboration with the Office of Accessibility and Inclusion (OAI), will ensure these contacts are well-equipped to handle initial reports. It is the responsibility of the Executive Council to maintain an updated record of these designated contacts and to provide this information to both the Co-Curricular Activities Office (CCA) and the OAI on a regular basis. 
        <br/><br/>The following flowchart details the process of escalating reports which should be transparent and known to all society members.
</p>
<div className="grid grid-cols-1 ">
<img src="https://res.cloudinary.com/ahbranding/image/upload/v1723409847/AHbranding/Escalation_process_Flowchart_axrfiy.jpg" className="w-6/12"/>



</div>


<div className="mt-6">
              <div className="container flex flex-col justify-center mx-auto px-0 ">
                <div className="space-y-4">
                    <Collapse accordionData={accordionData9}/>
                </div>
              </div>
            </div>
</section>,







<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-16">
      
      
          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>Points to Note</b>
            </h2>
            


       
      
          </div>
        </div>
      
        <div className="mt-6 flex justify-start w-full">
        <SliderWeek14_2 className='w-full' onImageIndexChange={handleImageIndexChange} />
      </div>
      </div>
      </section>,




<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-16">
      
      
          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>Reinforcement </b>
            </h2>
            <p className="mt-4 text-gray-600">
<br/> <br/>
The following questions focus on the steps to be taken when dealing with sexual harassment, student leadership in policy enforcement, and mandatory training requirements. This will help to ensure that you are aware of the correct procedures and the importance of these policies in maintaining a safe and respectful campus environment.            </p>



          </div>
        </div>
      

      
 <div className="max-w-4xl mx-auto mt-8 p-4 rounded-md">
      {currentQuestion < questionsData.length ? (
        <div>
          <h2 className="text-xl text-center font-semibold mb-8">{questionsData[currentQuestion].question}</h2>
          <div className="grid grid-cols-1 gap-4 mx-auto w-8/12">
            {questionsData[currentQuestion].options.map((option, index) => (
              <div
                key={index}
                className={`${
                  (showExplanation && option === questionsData[currentQuestion].correctAnswer) ? 'bg-green-500 text-white' :
                  (selectedOption === option ? 'bg-purple-200 ' : 'bg-white')
                } p-4 rounded-2xl m-1 text-start border-2 cursor-pointer `}
                onClick={() => handleOptionSelect(option)}
              >
                {option}
              </div>
            ))}
          </div>
          {selectedOption && (
            <div className="flex justify-center">
              <button
                className="btn btn-outline-primary mt-4"
                onClick={handleNextQuestion}
              >
                Show Answer
              </button>
            </div>
          )}
          {showExplanation && (
            <div className="mt-4">
              <p className="font-semibold">Correct Answer:</p>
              <p className="text-green-500">{questionsData[currentQuestion].correctAnswer}</p>
              <p className="font-semibold mt-2">Explanation:</p>
              <p>{questionsData[currentQuestion].explanation}</p>
              <div className="flex justify-center mt-4">
               {/* */}
               {currentQuestion + 1 < questionsData.length ? (
                <button
    className="btn btn-outline-primary"
    onClick={handleNextQuestionOrFinish}
  >
  Next Question
    
  </button>

               ) : null}
               
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <h1 className="text-2xl font-bold mb-4">Quiz Completed!</h1>
        </div>
      )}
    </div>
      
      </div>
      </section>,






<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      

      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-16">
      
      
          <div className="">
            <h2 className="text-xl font-medium sm:text-xl">
              <b>LUMS Policy on Conduct and Harassment Prevention in Student Societies</b>
            </h2>
            <p className="mt-4 text-gray-600">
            LUMS is dedicated to fostering a safe, respectful, and inclusive environment. As part of our commitment, we have established a specific policy to guide conduct and harassment prevention within student societies. This policy aligns with LUMS' broader ethical framework and ensures a nurturing space for all members.
<br/> <br/>
This document is an extension of the "LUMS Sexual Harassment Policy" and the "Code of Conduct," tailored specifically for student societies. It provides detailed guidelines on handling various interactions and scenarios that may arise within these groups.
            </p>



            <div className="mt-8">
      <h5 className="text-lg font-bold mb-4">Key Areas Covered:</h5>

      <ul className="list-disc list-inside">
        <li className="mb-2">Adherence to Main Policies</li>
        <li className="mb-2">Reporting Mechanisms within Societies</li>
        <li className="mb-2">The Role of Co-Curricular Activities Office</li>
        <li className="mb-2">Direct Reporting to the Office of Accessibility and Inclusion (OAI)</li>
        <li className="mb-2">Training and Awareness</li>
        <li className="mb-2">Event Management and Conduct</li>
        <li className="mb-2">Confidentiality and Data Handling</li>
        <li className="mb-2">Review and Feedback Mechanisms</li>
        <li className="mb-2">Consequences of Non-Compliance</li>
      </ul>
    </div>


    <p className="mt-8">All students are encouraged to read this document thoroughly to understand their responsibilities and the resources available to them. It's crucial for maintaining a respectful and harassment-free environment within our student societies.</p>
      
            <div className="mt-6">



            <>
  {/* File Uploading Progress Form */}
  <div>
    {/* Uploading File Content */}
    <div className="mb-2 flex justify-between items-center">
      <div className="flex items-center gap-x-3">
        <span className="w-8 h-8 flex justify-center items-center border border-gray-200 text-gray-500 rounded-lg darki:border-neutral-700">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m.75 12 3 3m0 0 3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
</svg>

        </span>
        <div>
          <Link target="blank" to={'https://drive.google.com/file/d/1dk2sVmLHijIV2MI0X7PR3bXN69uBimBO/view?usp=sharing'} className="link link-primary text-sm m-0 font-medium darki:text-white">
          Policy on Conduct and Harassment Prevention
          </Link>
          <p className="m-0 text-xs text-gray-500 darki:text-gray-500">.DOCSX</p>
        </div>

      </div>
      
    </div>
    {/* End Uploading File Content */}
    {/* Progress Bar */}

    {/* End Progress Bar */}
  </div>
  {/* End File Uploading Progress Form */}
</>



              
            </div>
          </div>
        </div>
      
      
      </div>
      </section>,



<section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
{/* Step 1 content */}
<Confetti numberOfPieces={100} />
<div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
  <div className="grid grid-cols-1 gap-8 lg:gap-16">


    <div className="">
      <h2 className="text-xl font-medium sm:text-xl">
        <b>
        Week 14 Conclusion: Addressing and Understanding Harassment        
        </b>
      </h2>
      <p class="mt-4 text-gray-600">


      Congratulations on completing Week 14 of the Ascent Leadership Program at LUMS. This week has been a profound journey into understanding and addressing harassment, a critical and sensitive issue in our society and within student communities.


</p>



      <div className="mt-6 grid grid-cols-1 gap-6">
<div className="space-y-4">


<Collapse accordionData={accordionData7} />


</div>




</div>




                <ul  className="block p-4" style={{listStyle:'circle'}}>

  </ul>
 
    </div>
  </div>
</div>
</section>
    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   {/* Step 1 content */}
    //   <h2 className="text-xl font-medium sm:text-xl">
    //     <b>Case Study: Howard Schultz and Starbucks</b>
    //   </h2>
    //   <p className="mt-4 text-gray-600">

    //   </p>

    //   <ul
    //     className="mt-4 bg-gray-100 p-2 rounded-xl border-2 border-gray-200 text-sm"
    //     style={{ listStyle: "circle" }}
    //   >
    //     <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-primary text-white w-max py-1 px-3 rounded-full">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke-width="1.5"
    //         stroke="currentColor"
    //         className="w-6 h-6"
    //       >
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
    //         />
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    //         />
    //       </svg>
    //       Review Peer's SWOT Analysis
    //     </p>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Strength: </p>
    //     <li className="mx-6">{assignedReview.strengths}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Weaknesses: </p>
    //     <li className="mx-6">{assignedReview.weaknesses}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Opportunities: </p>
    //     <li className="mx-6">{assignedReview.opportunities}</li>

    //     <p className="mx-6 mt-8 mb-2 font-bold">Threats: </p>
    //     <li className="mx-6 mb-4">{assignedReview.threats}</li>
    //   </ul>

    //   <div className="mt-6 flex justify-start w-full">
    //     <div className="form-control w-full ">
    //       <label className="label">
    //         <span className="label-text">Feedback</span>
    //       </label>
    //       <textarea
    //         value={swotFeedback}
    //         className="textarea textarea-sm textarea-bordered h-20"
    //         placeholder="Type here..."
    //         onChange={(e) => setFeedback(e.target.value)}
    //       ></textarea>
    //     </div>
    //   </div>

    //   <button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission}

    //   disabled={
    //     assignedReview === ''}
      
    //   >
    //     Submit
    //   </button>


    // </section>,
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction";

      case 1:
        return "Objectives";

      case 2:
        return "View Slides";

      case 3:
        return "Embarking on the Journey of Self-Awareness";

      case 4:
        return "SWOT Analysis";

      case 5:
        return "Peer Review | SWOT Analysis";

      default:
        return "Page";
    }
  };

  return (
    <div>


<div>
      <div>
        <>
          <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

          <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
          <NavbarBack/>
            <div className="w-full px-6 py-6 mx-auto">

         
            <div className="w-full bg-white rounded-full">

            {steps[currentStep]}

            </div>


              <div className="btm-nav btm-nav-lg bg-primary shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                <span className="text-sm hidden md:flex">{`Step `+(currentStep+1)}</span>

                <div className="flex justify-cente px-6 flex-row items-center gap-4">
                  <p className="text-sm font-medium m-0">
                    {Math.floor(((currentStep) / totalSteps) * 100)}%
                  </p>
                  <progress
                    className="progress bg-indigo-500 progress-success w-56"
                    value={Math.floor(((currentStep) / totalSteps) * 100)}
                    max="100"
                  ></progress>
                  <p className="text-sm font-medium m-0">🏆</p>
                </div>

                <div>
                {(() => {
        if (currentStep<totalSteps) {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                      disabled={
                        (currentStep < 1)
                        }
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleNext}
                      // disabled={
                      //   currentStep === 2 && (currentImageIndex<6) ||
                      //   currentStep === 4 && (swotSubmission.threats === '' && swotSubmission.strengths === '' && swotSubmission.weaknesses === '' && swotSubmission.opportunities === '')
                      //   }
                    >
                      Next ›
                    </button>
                  </div>
          )
        }
        else {
          return (
            <div className="join">
                    <button
                      className="btn bg-locked join-item"
                      onClick={handleBack}
                    >
                      ‹ Back
                    </button>
                    <button
                      className="btn bg-locked join-item"
                      onClick={() => navigate(-1)}
                    >
                      Finish Week ›
                    </button>
                  </div>
          )
        }
      })()}
                  
                </div>
              </div>
            </div>
          </main>
        </>
      </div>
    </div>

    {/* <div className="bgGray">
      <div className="">
        <div className="flex flex-col flex-1 bgGray h-full">
          <main className="flex-1">

         
            <div className="">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full mb-28">
                <div></div>

                {steps[currentStep]}

     
                <div className="fixed inset-x-0 bottom-0 mt-12">
                  <div className="relative bg-primary flex justify-between mx-auto w-full items-center text-white statusTray">
                    <p className="text-sm font-medium">
                      {getTitle(currentStep)}
                    </p>

                    <div className="flex justify-row items-center gap-4">
                      <p className="text-sm font-medium">
                        {Math.floor(((currentStep + 1) / totalSteps) * 100)}%
                      </p>
                      <progress
                        className="progress bg-indigo-500 progress-success w-56"
                        value={Math.floor(
                          ((currentStep + 1) / totalSteps) * 100
                        )}
                        max="100"
                      ></progress>
                      <p className="text-sm font-medium">🏆</p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <button
                        className="btn btn-sm bg-white text-primary hover:text-white"
                        onClick={handleBack}
                        style={{
                          display: currentStep === 0 ? "none" : "block",
                        }}
                      >
                        Back
                      </button>
                   

                      <button
                        className={`btn btn-sm bg-white text-primary hover:text-white`}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div> */}

    </div>
  );
};

export default Week13;
